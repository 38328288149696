import React from 'react';
import {Col, Grid, Row } from 'react-bootstrap';
import caravan from '../../assets/caravanvoorbeeld.jpg';
import astrid from '../../assets/astridcaravan.jpg'


const Wat = (location, title) => (
  <Grid className={ ''}>
    <Row className='padding-top-40'>
      <Col className='col-md-6 col-xs-12'>
        <h2>Wat?</h2>
        <p>
          Stadsklanken is een poëtische plattegrond van Elsene.
          Met deze plattegrond kan je (virtueel of met je smartphone in de hand) een wandeling maken door de gemeente. Bij de verschillende gemarkeerde plekken op de kaart kan je telkens een gedicht lezen,
          en/of een beeld of filmpje bekijken.
          De gedichten zijn geschreven door Astrid Haerens, en zijn gebaseerd op het poëtisch onderzoek dat zij voerde in Elsene.
        </p>
        <h2>Quoi?</h2>
        <p>
        'Sons citadins' est un plan poétique de la commune d'Ixelles. 
        Il vous permet de fair une balade guidée (virtuelle ou smartphone dans la main)
        à travers la commune. Aux endroits marqués sur la carte, vous pouvez
        lire un poème et/ou regarder une image ou une vidéo. Les poèmes ont été
        écrits par Astrid Haerens, à partir d'une recherche poétique qu'elle a
        menée à Ixelles.
        </p>
        <h2>About</h2>
        <p>
        'City Sounds' is a poetical map of Ixelles. 
        It guides you on a walk through the municipality, virtually or with your smartphone at hand. On
        various locations highlighted on the map, you can read a poem, look at
        an image and/or watch a video. The poems were written by Astrid Haerens,
        who was inspired by a poetical investigation she conducted on the spot.
        </p>
      </Col>
      <Col className='col-md-6 col-xs-12'>
       <img src={caravan} alt='caravan'/>
      </Col>
    </Row>
    <Row className='padding-top-40'>
      <Col className='col-md-6 col-xs-12'>
        <img className='width-70' src={astrid} alt='astrid'/>
      </Col>
      <Col className='col-md-6 col-xs-12'>
      <h2>Hoe</h2>
      <p>
        Dichter Astrid Haerens kampeerde tijdens de maand juni in een caravan op allerhande plekken in de Brusselse gemeente. Ze stelde vragen, luisterde, keek, organiseerde poëzieworkshops en poëtische interventies. Zo gaf ze workshops in Basisschool De Wimpel, schrijfateliers in PSC Sint-Alexius, en sprak ze met tientallen voorbijgangers en bewoners op zes verschillende plaatsen.
        Op basis van alle verhalen, klanken en ritmes probeerde ze de hartslag van Elsene en haar inwoners te vangen in gedichten.
        Tijdens het wandelen van punt A naar punt B kan je luisteren naar korte podcasts die Astrid samenstelde uit materiaal opgenomen tijdens het onderzoek.
      </p>
      <h2>Comment</h2>
      <p>
        Durant le mois de juin, la poétesse Astrid Haerens a bivouaqué dans une
        caravane à différents endroits dans la commune bruxelloise. Elle posait
        des questions, écoutait, organisait des ateliers de poésie et des
        interventions poétiques. Elle a donné, entre autres, des ateliers
        d'écriture à l'école primaire De Wimpel et au centre psycho-social
        Sint-Alexius, et elle a parlé avec des dizaines de passants et habitants
        à six endroits différents. A la base des histoires, sons et rythmes
        ainsi interceptés, elle a essayé de capturer le battement de coeur
        d'Ixelles et de ses habitants dans des poèmes. Pendant que vous vous
        baladez, vous pouvez écouter les brefs podcasts qu'Astrid a composé du
        matériel enregistré pendant la recherche.
      </p>
      <h2>How</h2>
      <p>
        During the month of June, the poetess Astrid Haerens has been camping in
        a caravan in all sorts of places in this Brussels municipality. She
        asked questions, listened, looked, and organised poetry workshops and
        poetical interventions. She has given writing workshops in the
        elementary school De Wimpel and the psychosocial centre Sint-Alexius,
        and talked to dozens of passers-by and inhabitants at six different
        locations. She tried to capture the heartbeat of Ixelles and its
        inhabitants in poems based on all the stories, sounds and rhythms she
        heard. While walking from A to B, you can listen to short podcasts
        Astrid compiled from material she recorded during the investigation.
      </p>
      </Col>
    </Row>
    <Row className='padding-top-40'>
      <Col className='col-md-4 col-xs-12'>
        <p>Heb je zelf ideeën omtrent Stadsklanken? <br/>
        Hoor je unieke geluiden in je straat? <br/>
        Zelf zin om deze kaart creatief (schrijvend, tekenend, zingend, ...) aan te vullen?</p>
        <br/>
        <p>Vertel het aan Astrid via <br/><a href='mailto:haerensastrid@gmail.com'>haerensastrid@gmail.com.</a></p>
      </Col>
      <Col className='col-md-4 col-xs-12'>
        <p>Avez-vous des idées sur 'Sons citadins'?<br/>
        Entendez-vous des sons uniques dans votre rue?<br/>
        Avez-vous envie de compléter cette carte d'une manière créative (avec
          des dessins, des chansons, des écrits...)?<br/></p>
        <p>Contactez Astrid via <br/><a href='mailto:haerensastrid@gmail.com'>haerensastrid@gmail.com.</a>
        </p>
      </Col>
      <Col className='col-md-4 col-xs-12'>
        <p>Do you have ideas concerning 'City Sounds' yourself?<br/>
        Do you hear unique sounds in your street? <br/>
        Would you like to complete this map in a creative way (writing, drawing, singing...)?<br/></p>
        <p>Tell Astrid via <br/><a href='mailto:haerensastrid@gmail.com'>haerensastrid@gmail.com.</a></p>
      </Col>
    </Row>
    <Row className='padding-top-40'>
      <Col className='col-md-12 col-xs-12'>
      <p>
      I.s.m. Re:space, gemeente Elsene.<br/>
      Vertalingen: Steven Haerens (dank!)<br/>
      Website: <a href='mailto:joni.de.borger@gmail.com'>Joni De Borger</a> (dank!)
      </p>
      </Col>
    </Row>
  </Grid>
);

export default Wat;


/*
Stadsklanken is een poëtische plattegrond van Elsene.
Met deze plattegrond kan je (virtueel of met je smartphone in de hand) een wandeling maken door de gemeente. Bij de verschillende gemarkeerde plekken op de kaart kan je telkens een gedicht lezen,
en/of een beeld of filmpje bekijken.
De gedichten zijn geschreven door Astrid Haerens, en zijn gebaseerd op het poëtisch onderzoek dat zij voerde in Elsene.
*/
