import React, {Component} from 'react';
import { Col } from 'react-bootstrap';

class  DetailImg extends Component {
  render() {
    if(this.props.img) {
      return (
        <Col>
          <img className='detail-img' alt="img" src={process.env.PUBLIC_URL + this.props.img} />;
        </Col>
      )
    } else {
      return (
        null
      )
    }
  }
}


export default DetailImg;
