import React, {Component} from 'react';
import { Col } from 'react-bootstrap';

class  DetailVideo extends Component {
  render() {
    if(this.props.video) {
      return (
        <Col>
          <iframe className='detail-video' src={this.props.video} frameBorder="0" allowFullScreen></iframe>
        </Col>
      )
    } else {
      return (
        null
      )
    }
  }
}


export default DetailVideo;
