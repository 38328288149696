import Player from 'react-soundcloud-player'
import React, { Component } from 'react'
import {Glyphicon} from 'react-bootstrap';

class AudioPlayer extends Component {

  componentDidMount() {
      console.log('player mounted')
      //this.refs.player.togglePlay();
  }

  render () {
    const {audioLocation, audioSelected} = this.props;
    if(audioSelected) {
      return (
        <div className='audio-container'>
          <Player ref="player" audio_id={audioLocation.soundcloud} title={audioLocation.title} playing='true' />
          <div className='audio-close' onClick={()=>this.props.onCloseClick()}><Glyphicon className='icon-big' glyph='remove'/></div>
        </div>
      )
    } else {
      return ( null )
    }

  }
}

export default AudioPlayer;
