import React, {Component} from 'react';
import { Polyline } from 'react-leaflet';

class  RouteLine extends Component {
  render() {
    //const { center, detailSelected, onClick, id, radius } = this.props;
    let color = '#f39c12';
    let positions = [
      [50.8271025, 4.3720921],
        [50.82708, 4.37211],
        [50.82703, 4.37225],
        [50.8270337, 4.372248],
        [50.82708, 4.37235],
        [50.827077, 4.3723473],
        [50.82698, 4.3724],
        [50.82697, 4.37242],
        [50.82675, 4.37263],
        [50.82649, 4.37286],
        [50.8264932, 4.3728626],
        [50.82651, 4.37294],
        [50.82684, 4.37414],
        [50.82701, 4.37467],
        [50.8270101, 4.3746735],
        [50.82742, 4.37386],
        [50.8276, 4.37358],
        [50.827596, 4.3735799],
        [50.82801, 4.37307],
        [50.82835, 4.37261],
        [50.82864, 4.37223],
        [50.8286412, 4.3722285],
        [50.82878, 4.37232],
        [50.82937, 4.37264],
        [50.82976, 4.37287],
        [50.82979, 4.37288],
        [50.82998, 4.373],
        [50.83073, 4.37348],
        [50.8316, 4.37401],
        [50.831602, 4.3740053],
        [50.83131, 4.37455],
        [50.8313095, 4.3745549],
        [50.83124, 4.37569],
        [50.8312413, 4.3756947],
        [50.83233, 4.37561],
        [50.83235, 4.37561],
        [50.83237, 4.37561],
        [50.83239, 4.37563],
        [50.83243, 4.37566],
        [50.8324283, 4.3756583],
        [50.8317, 4.37656],
        [50.8317019, 4.3765602],
        //tot punt 4
          [50.8317019, 4.3765602],
          [50.83243, 4.37566],
          [50.83262, 4.37544],
          [50.83285, 4.37512],
          [50.8328514, 4.3751209],
          [50.83334, 4.37611],
          [50.8334, 4.37624],
          [50.83347, 4.37637],
          [50.83373, 4.37695],
          [50.8337306, 4.3769463],
          [50.83469, 4.37595],
          [50.8355, 4.3751],
          [50.8355028, 4.3750998],
          [50.83537, 4.37485],
          [50.83528, 4.37463],
          [50.8352, 4.37442],
          [50.83503, 4.37404],
          [50.8350303, 4.3740378],
        //tot punt 5
        [50.8349491, 4.3738536],
            [50.83465, 4.37317],
            [50.83457, 4.37299],
            [50.8345678, 4.3729876],
            [50.83536, 4.37203],
            [50.83542, 4.37195],
            [50.83555, 4.37178],
            [50.83581, 4.37148],
            [50.83605, 4.37119],
            [50.83631, 4.37088],
            [50.83651, 4.37063],
            [50.83685, 4.3702],
            [50.83775, 4.36904],
            [50.83786, 4.36891],
            [50.83816, 4.36858],
            [50.8381562, 4.3685824],
            [50.83809, 4.36844],
            [50.8380936, 4.3684429],
            [50.83787, 4.36837],
            [50.8378715, 4.3683668],
        //tot punt 6
        [50.8378715, 4.3683668],
          [50.83763, 4.36829],
          [50.8376343, 4.3682855],
          [50.83628, 4.36778],
          [50.8362791, 4.3677758],
          [50.83617, 4.36817],
          [50.8361651, 4.3681688],
          [50.83559, 4.36803],
          [50.83511, 4.36784],
          [50.83503, 4.36782],
          [50.83495, 4.36774],
          [50.83462, 4.36732],
          [50.83455, 4.36724],
          [50.8345514, 4.3672385],
          [50.8346, 4.36714],
          [50.83474, 4.36688],
          [50.83504, 4.36634],
          [50.8350393, 4.3663359],
          [50.83503, 4.36614],
          [50.8350331, 4.3661364],
        //tot punt 8
        [50.8350929, 4.3663231],
          [50.83504, 4.36634],
          [50.8350393, 4.3663359],
          [50.83474, 4.36688],
          [50.8346, 4.36714],
          [50.83455, 4.36724],
          [50.8345, 4.36731],
          [50.83448, 4.36735],
          [50.8344779, 4.3673456],
          [50.83405, 4.36688],
          [50.83345, 4.36657],
          [50.8334539, 4.3665652],
          [50.83347, 4.36651],
          [50.83353, 4.36623],
          [50.83368, 4.366],
          [50.8336763, 4.3659958],
          [50.83362, 4.36585],
          [50.83319, 4.36516],
          [50.83251, 4.36402],
          [50.83236, 4.36378],
          [50.8323598, 4.3637799],
          [50.83205, 4.36377],
          [50.83184, 4.36379],
          [50.8317, 4.36381],
          [50.8317047, 4.36381],
        //last
        [50.8317047, 4.36381],
          [50.8312, 4.36389],
          [50.83104, 4.36391],
          [50.831045, 4.3639143],
          [50.83122, 4.36415],
          [50.83167, 4.36476],
          [50.83169, 4.3648],
          [50.83191, 4.36509],
          [50.83231, 4.36564],
          [50.8323083, 4.3656369],
          [50.83215, 4.36576],
          [50.83191, 4.36602],
          [50.83164, 4.36628],
          [50.83101, 4.3669],
          [50.83091, 4.36699],
          [50.83068, 4.36722],
          [50.82943, 4.3685],
          [50.82924, 4.36868],
          [50.8292403, 4.3686802],
          [50.82925, 4.36933],
          [50.82928, 4.36961],
          [50.82937, 4.37],
          [50.82942, 4.37008],
          [50.8294167, 4.370085],
          [50.82891, 4.37052],
          [50.82833, 4.371],
          [50.82782, 4.37145],
          [50.82768, 4.37158],
          [50.82746, 4.37173],
          [50.82722, 4.37194],
          [50.8271, 4.37209],
          [50.8271025, 4.3720921]

    ];

    return (
      <Polyline color={color} positions={positions} weight={1.5}/>
    )
  }
}


export default RouteLine;
