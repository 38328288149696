import React, {Component} from 'react';
import { Circle } from 'react-leaflet';

class  AudioMarker extends Component {
  render() {
    const { center, audioSelected, onClick, id } = this.props;
    let color = '#e67e22';
    let marker = null;
    if(audioSelected === (id+1)) {
      marker = <Circle center={[center.lat, center.lng]} fillColor={color} fillOpacity={0.15} radius={20*2.8} stroke={false}/>
    }

    return (
      <span>
        {marker}
        <Circle center={[center.lat, center.lng]} fillColor={color} fillOpacity={0.8} onClick={() => onClick(id)} radius={20*1.2} stroke={false}/>
      </span>
    )
  }
}


export default AudioMarker;
