import React, {Component} from 'react';
import { Circle } from 'react-leaflet';

class  FocusLocationMarker extends Component {
  render() {
    const { center, detailSelected, onClick, id, radius } = this.props;
    let color = '#f1c40f';
    let marker = null;
    if(detailSelected === (id+1)) {
      color = '#f1c40f';
      marker = <Circle center={[center.lat, center.lng]} fillColor={color} fillOpacity={0.15} onClick={() => onClick(id)} radius={radius*2.8} stroke={false}/>
    }

    return (
      <span>
        {marker}
        <Circle center={[center.lat, center.lng]} fillColor={color} fillOpacity={0.3} onClick={() => onClick(id)} radius={radius*1.6} stroke={false}/>
        <Circle center={[center.lat, center.lng]} fillColor={color} fillOpacity={0.8} onClick={() => onClick(id)} radius={radius*0.6} stroke={false}/>
      </span>
    )
  }
}


export default FocusLocationMarker;
