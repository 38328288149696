import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAudioSelected, setDetailSelected, setMobileTrue } from '../actions';
import MapLayer from '../components/MapLayer';

/*
TODO:
  - remove control store
  - add onMapClick
*/
class MapContainer extends Component {
  constructor(props) {
      super(props);
      this._handleAudioMarkerClick = this._handleAudioMarkerClick.bind(this);
      this._handleMapClick = this._handleMapClick.bind(this);
      this._handleMarkerClick = this._handleMarkerClick.bind(this);
      //const isMobile = window.innerWidth <= 667;
      this.zoom = this.props.zoom;
      this.center = this.props.center;
      this.geo = null;
      if(window.innerWidth <= 350) {
        this.zoom = 14;
        this.center = {
          lat: 50.832250,
          lng: 4.369913
        }
        this.props.dispatch(setMobileTrue());
      } else if (window.innerWidth <= 667) {
        this.zoom = 15;
        this.center = {
          lat: 50.832250,
          lng: 4.369913
        }
        this.props.dispatch(setMobileTrue());
      }
      //this.props.dispatch(setMobileTrue());
  }

  _handleAudioMarkerClick(id) {
    this.props.dispatch(setAudioSelected(id+1));
  }

  _handleMapClick(e) {
    //const latlng = {lat: e.latlng.lat, lng: e.latlng.lng};
    //this.props.dispatch(fetchLocation(latlng));
  }

  _handleMarkerClick(id) {
    console.log('marker click', id);
    this.props.dispatch(setDetailSelected(id+1));
  }

  render() {

    return (
      <span>
        <MapLayer audioLocations={this.props.audioLocations} audioSelected={this.props.audioSelected} center={this.center} detailSelected={this.props.detailSelected} isMobile={this.props.isMobile} locations={this.props.locations} onAudioMarkerClick={this._handleAudioMarkerClick} onMapClick={this._handleMapClick} onMarkerClick={this._handleMarkerClick} zoom={this.zoom}/>
      </span>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    audioLocations: state.audioLocations,
    audioSelected: state.audioSelected,
    center : state.center,
    detailSelected: state.detailSelected,
    isMobile: state.isMobile,
    locations : state.locations,
    zoom : state.zoom
  }
}

/*
const mapDispatchToProps = (dispatch) => {
  return {
    onMapClick: (location) => {
      dispatch(addFocusLocation(location));
    }
  }
}
*/

export default connect(mapStateToProps)(MapContainer);
