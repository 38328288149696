import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import airDemo from './js/reducers'
import App from './js/containers/App';
import './css/bootstrap.min.css';
import './css/bootstrap-theme.min.css';
import './css/index.css';

const loggerMiddleWare = createLogger();

const store = createStore(
  airDemo,
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleWare
  )
);

console.log(store.getState())

store.subscribe(()=> {
  console.log('store changed', store.getState());
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
