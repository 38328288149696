import React, { Component } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import AudioMarker from './markers/AudioMarker';
import FocusLocationMarker from './markers/FocusLocationMarker';
import MyMarker from './markers/MyMarker';
import RouteLine from './RouteLine';

class MapLayer extends Component {
  constructor(props){
    super(props);
    this.getInnerRef = this.getInnerRef.bind(this);
    setInterval(this.keepUpdatingLocation, 5000);
    this.innerRef = null;
  }

  getInnerRef(ref) {
    this.innerRef = ref;
    console.log('the innerRef: ', this.innerRef)
  }

  keepUpdatingLocation() {
    this.innerRef && this.innerRef.getLocation();
    console.log('update location');
  }

  render() {
    const { audioLocations, audioSelected, center, detailSelected, isMobile, locations, onAudioMarkerClick, onMapClick, onMarkerClick, zoom } = this.props;

    return (
      <Map className={isMobile ? 'mobile-map' : ''} center={center} onClick={(e) => onMapClick(e)} zoom={zoom} zoomControl={true}>
        <TileLayer url='https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png'/>
        <RouteLine />
        {isMobile ? <MyMarker ref={this.getInnerRef} radius={50}/> : null}
        {locations.map( (location, index) => (
          <FocusLocationMarker key={`marker_${index}`} center={location.marker} color='#3498db' detailSelected={detailSelected} id={index} onClick={(id) => onMarkerClick(id)} radius={50} />
        ))}
        {audioLocations.map( (location, index) => (
          <AudioMarker key={`marker_${index}`} center={location.marker} color='#3498db' audioSelected={audioSelected} id={index} onClick={(id) => onAudioMarkerClick(id)} />
        ))}
      </Map>
    )
  }
}

export default MapLayer;

/*
setTimeout(this.keepUpdatingLocation(), 5000);
}


*/

/*{markers.locations.map( (location, index) => (
  <FocusLocationMarker key={index} center={location.latlng} fillColor='white' radius={50} />
))}*/

/*
  _getHistory(options){
    console.log(options);
    this.setState({
      markers: options
    });
  }

  _addData(options){
    console.log(options);
    let markers = this.state.markers.slice();
    markers.push(options);
    console.log(markers);
    this.setState({
      markers: options
    });
  }
  */
