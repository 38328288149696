import React, {Component} from 'react';
import { Circle } from 'react-leaflet';
import { geolocated, geoPropTypes } from 'react-geolocated';

class  MyMarker extends Component {

  render() {
    const { radius } = this.props;
    let color = '#3498db';
    let marker = null;

    console.log('marker rendered');
    return (
      !this.props.isGeolocationAvailable
      ? null
      : !this.props.isGeolocationEnabled
        ? null
        : this.props.coords
          ? <span id='marker-location'>
              {marker}
              <Circle center={[this.props.coords.latitude, this.props.coords.longitude]} fillColor={color} color={color} fillOpacity={0.2} radius={radius*1} stroke={true} weight={1}/>
              <Circle center={[this.props.coords.latitude, this.props.coords.longitude]} fillColor={color} fillOpacity={0.8} radius={radius*0.6} stroke={false}/>
            </span>
          : <div>Getting the location data&hellip; </div>
        );
    /*
    <table>
      <tbody>
        <tr><td>latitude</td><td>{this.props.coords.latitude}</td></tr>
        <tr><td>longitude</td><td>{this.props.coords.latitude}</td></tr>
        <tr><td>altitude</td><td>{this.props.coords.altitude}</td></tr>
        <tr><td>heading</td><td>{this.props.coords.heading}</td></tr>
        <tr><td>speed</td><td>{this.props.coords.speed}</td></tr>
      </tbody>
    </table>
    return (
      <span>
        {marker}
        <h3>{id}</h3>
        <Circle center={[center.lat, center.lng]} fillColor={color} fillOpacity={0.3} onClick={() => onClick(id)} radius={radius*1.6} stroke={false}/>
        <Circle center={[center.lat, center.lng]} fillColor={color} fillOpacity={0.8} onClick={() => onClick(id)} radius={radius*0.6} stroke={false}/>
      </span>
    )*/
  }
}

MyMarker.propTypes = { ...MyMarker.propTypes, ...geoPropTypes };

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  watchPosition: true,
  userDecisionTimeout: 5000,
})(MyMarker);
