import React, { Component } from 'react';
import {Navbar, Nav, NavItem} from 'react-bootstrap';
import logo from '../../assets/logo.png';

class  CustomNav extends Component {
  render() {
    const {navActive, language, onLanguageClick, onNavClick} = this.props;
    const text = [
      ['Kaart', 'Wat'],
      ['Plan', 'A propos'],
      ['Map', 'About'],
    ];
    ///SOrry no time to figure it out
    let bodyIndex = 0;
    if(this.props.language === 'FR') {
      bodyIndex = 1;
    } else if (this.props.language === 'EN') {
      bodyIndex = 2;
    }

    return (
    <Navbar collapseOnSelect fixedTop>
      <Navbar.Header>
        <Navbar.Brand>
          <a href="#" onClick={() => onNavClick(1)}>
            <img src={logo} width="150" height="30" alt='stadsklanken'/>
          </a>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav>
          <NavItem className={ navActive===1 ? 'active' : ''} eventKey={1} href="#" onClick={() => onNavClick(1)}>{text[bodyIndex][0]}</NavItem>
          <NavItem className={ navActive===2 ? 'active' : ''} eventKey={2} href="#" onClick={() => onNavClick(2)}>{text[bodyIndex][1]}</NavItem>
          <NavItem className={ navActive===3 ? 'active' : ''} eventKey={3} href="#" onClick={() => onNavClick(3)}>Making of</NavItem>
        </Nav>
        <Nav pullRight>
          <NavItem className={ language==='NL' ? 'active' : ''} eventKey={1} onClick={() => onLanguageClick('NL')} href="#">
            NL
          </NavItem>
          <NavItem className={ language==='FR' ? 'active' : ''} eventKey={2} onClick={() => onLanguageClick('FR')} href="#">
            FR
          </NavItem>
          <NavItem className={ language==='EN' ? 'active' : ''} eventKey={2} onClick={() => onLanguageClick('EN')} href="#">
            EN
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    );
  }
}

export default CustomNav;
