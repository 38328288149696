import { DISABLE_DETAIL_SELECTED, HIDE_LIST, SET_AUDIO_SELECTED, SET_DETAIL_SELECTED, SET_LANGUAGE, SET_NAVIGATION, SET_MOBILE_TRUE, SHOW_LIST } from '../actions';

const initialState = {
  audioSelected: 0,
  audioLocations: [
    {
      id: 1,
      marker: {
        lat: 50.829507,
        lng: 4.370145
      },
      soundcloud: '341878287',
      title: 'soundscape XL'
    },
    {
      id: 2,
      marker: {
        lat: 50.829849,
        lng: 4.372851
      },
      soundcloud: '341877480',
      title: 'stadsklankenkinderen'
    },
    {
      id: 3,
      marker: {
        lat: 50.834708,
        lng: 4.373261
      },
      soundcloud: '341876511',
      title: 'fragmentenstadsklanken'
    }
  ],
  center : {
    lat: 50.83214,
    lng: 4.374271
  },
  detailSelected: 0,
  isDetailSelected: false,
  isMobile: false,
  language: 'NL',
  navigation: 1,
  locations: [
    {
      id: 1,
      marker : {
        lat: 50.827291,
        lng: 4.372246
      },
      place: [
        'Flageyplein',
        'Place Flagey',
        'Place Flagey',
      ],
      title: [
        'Flageyplein',
        'Place Flagey',
        'Place Flagey',
      ],
      subtitle: [
        'scène I',
        'scène I',
        'scène I',
      ],
      body: [
          [
          "stel je voor",
          "de hele stad stopt plots met dansen",
          "staat stil",
          "luistert",
          "",
          "over het platgetrapte gras",
          "van de verboden te betreden grond",
          "zweven geluiden die niemand eerder hoorde",
          "",
          "een ijsberg die kraakt",
          "een giraf",
          "een nog uit te vinden woord",
          "tientallen walvissen zingen",
          "infrasoon gefluister",
          "van olifanten",
          "in de stad",
          "",
          "rond het plein stoppen wagens brommers buggy’s",
          "iedereen stapt uit en af",
          "troept samen",
          "voeten vormen een arena",
          "mensen luisteren het licht is fel ogen dicht stil",
          "",
          "waar moet men meer naar luisteren?",
          "",
          "men denkt na",
          "werpt ideeën",
          "de onverschillige lucht in:",
          "",
          "de grote moeten meer naar de kleine luisteren",
          "zeggen de grote",
          "",
          "de kleine moeten meer naar de grote luisteren",
          "zeggen de kleine",
          "",
          "de stille",
          "de oude",
          "de verlegen",
          "de eenzame",
          "het dissonante!",
          "",
          "een man, achteraan, blijft zitten",
          "zwijgt",
          "",
          "niet naar mij",
          "niet naar mij",
          "niet naar"
        ],
        [
          'imagine-toi',
         'soudain, toute la ville cesse de danser',
         's’arrête',
         'écoute',
         'au-dessus de l’herbe piétinée',
         'de la pelouse interdite',
         'flottent des sons que personne n’a jamais entendus',
         'un iceberg qui craque',
         'une girafe',
         'un mot qui reste à inventer',
         'des dizaines de baleines chantent',
         'un chuchotement infrasonore',
         'd’éléphants',
         'dans la ville',
         'autour de la place des voitures des motos des poussettes s’arrêtent',
         'tout le monde descend',
         's’attroupe',
         'des pieds forment une arène',
         'les gens écoutent la lumière aveugle les yeux fermés silence',
         'qu’est-ce qu’il faut écouter plus ?',
         'on réfléchit',
         'jette des idées',
         'dans l’air indifférent:',
         'les grands doivent écouter plus les petits',
         'disent les grands',
         'les petits doivent écouter plus les grands',
         'disent les petits',
         'les silencieux',
         'les vieux',
         'les timides',
         'les solitaires',
         'le dissonant!',
         'un homme au fond reste assis',
         'se tait',
         'pas moi',
         'pas moi',
         'pas',
        ],
        [
         'imagine',
         'the whole city suddenly stops dancing',
         'stands still',
         'listens',
         'over the trodden-down grass',
         'of the forbidden ground',
         'sounds are floating no-one has ever heard before',
         'the cracking of an iceberg',
         'a giraffe',
         'a word still to be invented',
         'tens of whales singing',
         'infrasonic whispering',
         'of elephants',
         'in the city',
         'round the square cars motorbikes buggies all stop',
         'everyone gets off',
         'crowds together',
         'feet form an arena',
         'people are listening the light is glaring eyes closed silence',
         'what do we have to listen to more?',
         'they think',
         'throw ideas',
         'into the indifferent air',
         'the great have to listen more to the small',
         'the great say',
         'the small have to listen more to the great',
         'the small say',
         'the silent',
         'the old',
         'the shy',
         'the lonely',
         'the dissonant!',
         'a man at the back stays seated',
         'keeps silent',
         'not to me',
         'not to me',
         'not',
        ],
      ],
    img: '/bilaltekening.jpg'
    },
    {
      id: 2,
      marker : {
        lat: 50.826347,
        lng: 4.372731
      },
      place: [
        'Flageyplein',
        'Place Flagey',
        'Place Flagey',
      ],
      title: [
        'Flageyplein',
        'Place Flagey',
        'Place Flagey',
      ],
      subtitle: [
        'scène II - voor Hugues',
        'scène II - pour Hugues',
        'scene II - for Hugues',
      ],
      body: [
        [
        '(de man)',
        '',
        'ah',
        '',
        'mijn hoofd klinkt schuldig',
        'mijn lichaam als een kolkende rivier',
        'mijn stem is de stem van een ander – ik bezit ze niet',
        '’s nachts hoor ik mezelf alleen maar aftakelen',
        '',
        'zelf hou ik van het geluid van een dik, wapperend blad',
        'het aanzetten van een versterker',
        'plof',
        '',
        'ik wil bedekt worden door Françoise Hardy',
        'honderd liedjes door elkaar in mijn hoofd',
        '’s nachts wanneer ik mijn eigen ademhaling hoor',
        '',
        'hoe zij mijn ogen sluit',
        'zich laat wiegen in monden',
        'van vroeger',
        '',
        'ik hoef geen nieuwe talen meer',
        'ik hoef niet alles te verstaan',
        '',
        'mijn stem is moe',
        'mijn hoofd is moe',
        'mijn hart verstuikt',
        '',
        'is dit genoeg?',
        '',
        'ik ben content',
        'als niemand',
        'me nog vragen stelt',
        '',
        '',
        '',
        '',
        '',
        ''
        ],
        [
         '(l’homme)',
         'ah',
         'ma tête sonne coupable',
         'mon corps comme une rivière tourbillonante',
         'ma voix est celle d’un autre – je ne la possède pas',
         'la nuit, je n’entends que ma propre dégénérescence',
         "quant à moi, j'aime bien le son d’une feuille épaisse qui volette",
         'l’allumage d’un amplificateur',
         'floc',
         'je veux être couvert de Françoise Hardy',
         'cent chansons pêle-mêle dans ma tête',
         'la nuit, quand j’entends ma propre respiration',
         'comment elle ferme mes yeux',
         'se laisse bercer dans des bouches',
         'd’autrefois',
         'il ne me faut plus de nouvelles langues',
         'il ne me faut plus tout comprendre',
         'ma voix est fatiguée',
         'ma tête est fatiguée',
         'je me suis fait une entorse au cœur',
         'ça suffit ?',
         'je suis content',
         'quand plus personne ne me pose de questions',
          '',
          '',
          '',
          '',
          '',
          ''
        ],
        [
         '(the man)',
         'ah',
         'my head sounds guilty',
         'my body like a swirling river',
         'my voice is someone other’s – I don’t possess it',
         'at night, I only hear myself decline',
         'personally, I like the sound of a thick, fluttering sheet of paper',
         'the sound of an amplifier switched on',
         'pop',
         'I want to be covered in Françoise Hardy',
         'hundred songs pell-mell in my head',
         'at night, when I hear my own breathing',
         'how she closes my eyes',
         'lets herself be lulled in mouths',
         'of old',
         'I don’t need new languages anymore',
         'I don’t need to understand it all',
         'my voice is tired ',
         'my head is tired',
         'my heart sprained',
         'is this enough?',
         'I’m satisfied',
         'when nobody asks me questions anymore',
          '',
          '',
          '',
          '',
          '',
          ''
        ],
      ]
    },
    {
    id: 3,
    marker : {
      lat: 50.828840,
      lng: 4.372669
    },
    place: [
      'Heilig-Kruisplein ',
      'Place Sainte-Croix',
      'Place Sainte-Croix',
    ],
    title: [
      'Heilig-Kruisplein ',
      'Place Sainte-Croix',
      'Place Sainte-Croix',
    ],
    subtitle: [
      '',
      '',
      '',
    ],
    body: [
      [
      'een meisje, ongeveer zes',
      'danst voor de trappen van de Heilige Kruiskerk',
      'werpt haar hart lichter dan een veer',
      'samen met haar blauwe jurk van voile',
      'de lucht in zingt',
      'laat het los!',
      'achter haar een moeder',
      'om haar armen wit verband',
      'wangen als druppende rijstzakjes',
      'de vier poten van verdriet',
      'als een koppige hond om de nek',
      'ze kijkt niet op vraagt niets en toch',
      'de mensen op de terrassen schudden hun hoofden',
      'trekken driftig van hun sigaret',
      'wist je dat dat in alle talen hetzelfde klinkt',
      'we kunnen dit niet dragen',
      'zoals we de keien niet kunnen dragen',
      'de straten niet om ons heen kunnen slaan als een sjaal',
      'om de schouders van de meisjes aan de tafels',
      'de babbelende meisjes de glanzende dunne luxe van',
      'een zonnebril om te bedekken',
      'het kijken naar',
      'een zwijgende',
      'voorbij schuifelende vrouw',
      '',
      '',
      '',
      '',
      '',
      ''
      ],
      [
       'une fillette, environ six ans',
       'danse devant les marches de l’Église Sainte-Croix',
       'lance son cœur plus léger qu’une plume',
       'avec sa jupe bleue de voile',
       'en l’air et chante',
       'libérée!',
       'derrière elle une mère',
       'sur les bras un bandage blanc',
       'les joues comme des sachets de riz qui gouttent',
       'les quatre pattes du chagrin',
       'comme un chien obstiné autour du cou',
       'elle ne lève pas les yeux ne demande rien et pourtant',
       'les gens sur les terrasses font non de la tête',
       'tirent sur leurs cigarettes avec fureur',
       'savais-tu que ça sonne pareil dans toutes les langues',
       'nous ne pouvons pas supporter cela',
       'comme nous ne pouvons pas porter les pavés',
       'ne pouvons pas nous envelopper dans les rues comme dans une écharpe',
       'les épaules des filles attablées ',
       'les filles qui bavardent le luxe reluisant et ténu de',
       'lunettes de soleil pour couvrir ',
       'le regard ',
       'sur une femme qui sans mot dire',
       'passe d’un pas traînant',
       '',
      '',
      '',
      '',
      '',
      '',
      ],
      [
       'a girl, about six',
       'is dancing in front of the steps of the Holy Cross Church',
       'she throws her heart lighter than a feather',
       'along with her blue voile dress',
       'in the air and sings',
       'let it go!',
       'behind her a mother',
       'white bandage around her arms',
       'cheeks like dripping rice pouches',
       'the four paws of sadness',
       'like a stubborn dog around her neck',
       'she does not look up asks for nothing and still',
       'the people on the pavement cafes shake their heads',
       'vehemently puff on their cigarettes',
       'did you know that sounds the same in any language',
       'we can’t bear this',
       'just as we can’t carry the cobblestones ',
       'can’t wrap the streets around us like a scarf',
       'around the shoulders of the girls at the tables',
       'the chattering girls the glittering thin luxury of ',
       'sunglasses to cover up',
       'the eyes on',
       'a silent',
       'woman shuffling by',
       '',
       '',
       '',
       '',
       '',
       '',
      ],
    ]
  },
  {
  id: 4,
  marker : {
    lat: 50.831711,
    lng: 4.376862
  },
  place: [
    'Kroonlaan (brug)',
    'Avenue de la Couronne (pont)',
    'Avenue de la Couronne (pont)',
  ],
  title: [
    'Kroonlaan (brug)',
    'Avenue de la Couronne (pont)',
    'Avenue de la Couronne (pont)',
  ],
  subtitle: [
    '',
    '',
    '',
  ],
  body: [
    [
    'als je stil zou zijn',
    'hoor je hoe de wind verplaatst',
    'een jubelend gordijn',
    'riedels van dakpannen',
    'duiven, verbolgen',
    'een door schachten druppelend gebed',
    '',
    'om de hoek zingt een kind haar',
    'voetstappen bij elkaar',
    'een sirene werpt zich als klaagvrouw',
    'ademt een tram in, buigt',
    'dooft uit',
     '',
    'wij omlijnen onze stemmen',
    'op de plattegrond van de stad:',
    'in deze zee van buigzaam steen',
    'bestaat niets wat niet gehoord',
     '',
    'als je stil zou zijn',
     '',
    'het zuchten van een straat',
    'troebele lagen lucht',
    'het stapelen van beweging',
    'een toren los van gewicht',
     '',
    'hoor je hoe iets fluistert',
    'aan het raam',
    'je naam',
    'als aanzet van',
    'dit poreus gedicht'
  ],
  [
   'si tu étais silencieux',
   'tu entendrais le cours du vent',
   'un rideau jubilant',
   'des ritournelles que jouent les tuiles',
   'des pigeons, indignés',
   'une prière ruisselante',
   'à travers des tuyaux',
   'après le coin, une enfant',
   'convoque ses pas en chantant',
   'une sirène se prosterne',
   'comme une pleureuse',
   'elle inhale un tram, elle se courbe',
   'et s’éteint',
   'nous retraçons nos voix ',
   'sur le plan de la ville',
   'dans cette mer de pierre pliable',
   'rien n’existe d’inentendu',
   'si tu étais silencieux',
   'les soupirs d’une rue',
   'des couches d’air troubles',
   'l’entassement de mouvements',
   'une tour détachée de son poids',
   'tu entendrais',
   'à la fenêtre entrouverte',
   'quelque chose chuchoter',
   'ton nom',
   'comme l’amorce',
   'de ce poème poreux',
  ],
  [
   'if you were silent',
   'you would hear the wind move',
   'a jubilating curtain',
   'jingling roof tiles',
   'pigeons, indignantly',
   'a prayer dripping through shafts',
   'around the corner, a child',
   'is singing her footsteps together',
   'a siren prostrates herself like a mourner',
   'inhales a tram, bows',
   'fades away',
   'we line in our voices',
   'on the city map',
   'in this sea of pliable stone',
   'nothing exists that is not heard',
   'if you were silent',
   'the sighings of a street',
   'cloudy layers of air',
   'the stacking of movement',
   'a tower weightless',
   'you would hear ',
   'by the window',
   'something wisper your name ',
   'as the start',
   'of this porous poem ',
  ],
],
  video: 'https://www.youtube.com/embed/VgPBTiLn6z0'
},
{
id: 5,
marker : {
  lat: 50.834686,
  lng: 4.374605
},
place: [
  'Viaductpark',
  'Parc du Viaduc',
  'Parc du Viaduc',
],
title: [
  'Viaductpark',
  'Parc du Viaduc',
  'Parc du Viaduc',
],
subtitle: [
  '', '', ''
  ],
body: [
  [
  'Juli en het licht in spikkels',
  'als de sproeten op je gezicht',
  'je trok met blinde vingers',
  'de taaie gladde sprieten uit de grond',
  'waarop je lag de aarde je lichaam',
  'volledig ondersteunend zodat jij met ogen dicht',
  'aangeschoten haast door hitte vergat',
  'hoe de stad voortdurend herschikt',
   '',
  'er gleed een lange smalle stilte door het gras',
  'als een lange smalle slang',
  'een doorzichtig soort niets',
  'kroop in je',
  '',
  'deed je zuchten',
  'naar het stollen van elke straat',
   '',
  'loste alles op',
  'waaruit je dacht',
  'te bestaan',
  '',
  '',
  '',
  '',
  '',
  ''
  ],
  [
   'juillet et la lumière en mouchetures',
   'comme les taches de rousseur sur ton visage',
   'tu arrachais avec des doigts aveugles ',
   "les brins d'herbe coriaces et lisses du sol",
   'sur lequel tu étais allongé la terre soutenant ton corps',
   'complètement de sorte que toi les yeux fermés',
   'presque gris à cause de la chaleur tu oubliais',
   'comment la ville se réarrange sans cesse',
   "un silence long et effilé se glissait dans l'herbe",
   'long et effilé comme un serpent',
   'une sorte de néant translucide',
   "s'enfilait en toi",
   'te faisait soupirer',
   'après la coagulation de chaque rue',
   'et dissolvait tout',
   'en ce que tu pensais',
   'consister',
  ],
  [
   'July and the light in speckles',
   'Like the freckles on your face',
   'with blind fingers you pulled',
   'the tough and smooth blades out of the ground',
   'you were lying on the earth supporting your body entirely',
   'so that you your eyes closed ',
   'almost tipsy because of the heat forgot',
   'how the city is rearranging ceaselessly',
   'a long and slender silence slid through the grass',
   'like a long and slender snake ',
   'a transparent kind of nothingness ',
   'sneaked inside you',
   'made you gasp',
   'for the clotting of each street',
   'and dissolved everything',
   'of which you thought you',
   'consisted',
  ],
],
},
{
id: 6,
marker : {
  lat: 50.837833,
  lng: 4.368226
},
place: [
  'Londenplein',
  'Place de Londres',
  'Place de Londres',
],
title: [
  'Londenplein',
  'Place de Londres',
  'Place de Londres',
],
subtitle: [
  'voor Chloé en Vince',
  'pour Chloé en Vince',
  'for Chloé en Vince',
],
body: [
  [
  'het is nacht',
  'ik zink naar de bodem',
  'van het bed',
  'de linoleum vloer',
  'de zeezieke stenen van de straat',
  '',
  'de eeuwige toon in mijn oren',
  'waarover vrachtwagens denderen',
  'vijf hoog sluit ik het enkel raam',
  'leg mijn oor op je arm je rug',
  'je ademende schouderbladen',
   '',
  'hoe er in je buik vergaderd wordt',
  'beesten door je lenden waden',
  'je gewrichten het gekraak van een plaat',
  'golven van taal tegen je schedelpan',
  '',
  'ik zal nooit complete stilte horen',
  'mijn hoofd een vol metrostel waar iedereen',
  'mompelend arm tegen arm',
  'rechtop blijft staan',
   '',
  'ik druk mijn lippen tegen de jouwe',
  'proef de oude bekenden in je mond',
  'traag kruip ik verder in je vergeet niet',
  'ik hoor je',
  'mijn liefste',
  'wil alleen nog zijn',
  'je naïeve stem',
  'je kalmerend tikken',
  'je bange hoofd',
  'je stilte, vernietigend',
   '',
  'je voet die verkoeling zoekt',
   '',
  'wanneer de eerste tram',
  'als een zwaluw het licht uitroept',
  '',
  '',
  '',
  '',
  '',
  ''
  ],
  [
   'il fait nuit ',
   'je coule vers le fond',
   'du lit',
   'le sol en linoléum',
   'les pierres naupathiques de la rue',
   'le ton éternel dans mes oreilles',
   'des camions qui passent dessus ',
   'au cinquième, je ferme la fenêtre simple vitrage',
   'je pose mon oreille sur ton bras sur ton dos',
   'tes omoplates respirantes',
   'la réunion animée dans ton ventre',
   'les bêtes pataugeant à travers tes lombes ',
   'tes jointures le craquement d’un disque vinyle',
   'des ondes de parole retentissant contre la voûte de ta crâne',
   'je n’éntendrai jamais silence complet',
   'ma tête une rame de métro bondée où tout le monde',
   'reste debout ',
   'murmurant bras contre bras',
   'je presse mes lèvres contre les tiennes',
   'je goûte les vieilles connaissances dans ta bouche',
   'lentement, je me glisse plus loin dedans n’oublie pas',
   'je t’entends',
   'mon amour',
   'je ne veux être plus que',
   'ta voix naïve',
   'tes tapes calmantes',
   'ta tête angoissée',
   'ton silence destructeur',
   'ton pied cherchant le froid',
   'quand le premier tram',
   'comme une hirondelle',
   'déclare la lumière',
  ],
  [
   'it is night-time',
   'I sink to the bottom ',
   'of the bed',
   'the linoleum floor',
   'the seasick bricks that pave the street',
   'the eternal tone in my ears',
   'over which trucks are thundering',
   'on the fifth floor I close the single-glass window',
   'I lay my ear on your arm on your back',
   'your breathing shoulder blades',
   'the lively assembly in your belly',
   'beasts wading through your loins',
   'your joints a creaking vinyl record',
   'waves of speech washing your skull',
   'I will never hear complete silence',
   'my head a crowded underground train where everyone ',
   'mumbling arm against arm',
   'stays upright',
   'I press my lips against yours',
   'taste the old acquaintances in your mouth',
   "slowly, I crawl further in you don't you forget",
   'I hear you',
   'my love',
   'I only want to be',
   'your naive voice',
   'your soothing taps',
   'your anxious head',
   'your destructive silence',
   'your foot looking for cooling',
   'when the first tram',
   'like a swallow',
   'declares the light',
  ],
],
},
{
id: 7,
marker : {
  lat: 50.835842,
  lng: 4.368104
},
place: [
  'Tulpstraat',
  'Rue de la Tulipe',
  'Rue de la Tulipe',
],
title: [
  'Tulpstraat',
  'Rue de la Tulipe',
  'Rue de la Tulipe',
],
subtitle: [
  'bom - voor Hamed & Cheikh',
  'bombe - pour Hamed & Cheikh',
  'bombe - pour Hamed & Cheikh',
],
body: [
  [
  'er zijn soorten van stilte',
  '',
  'de kabbelende',
  'de stilte van chaos',
  'de stilte voor / na',
  'de volmaakte',
  'stilte van de dood',
  'herken je meteen',
  'denk ik',
  '',
  'ik zoek een voortdurend razen',
  'van auto’s in de straat',
  'het houdt me hier',
  'dit bed deze muur',
  'deze Tulpstraat dit Brussel',
  '',
  'ik haat het soort stilte',
  'waar je de tijd doorheen hoort trippelen',
  'een piepend ademen',
  'gedrup in een gootsteen',
  'ruimtes waarin wordt gewacht',
   '',
  'elk geluid is een goed',
  '',
  'het is heet in mijn raamloze kamer',
  'de nacht sleept versleten dromen binnen',
   '',
  'het getik van de ventilator',
  'buigt steeds om ',
  'tot het geluid van een trekker',
  'een kogelloos geweer',
  '',
  'elk nacht sterf ik zonder klank',
  '',
  'maar niet hier niet',
  'in deze stad',
   '',
  'valt de stilte',
  'hoe dan ook',
   '',
  'meer op',
  'dan een bom',
  '',
  '',
  '',
  '',
  '',
  ''
  ],
  [
    'le silence est pluriel',
    'le clapotant',
    'le silence du chaos',
    'le silence avant/après',
    'le silence parfait',
    'de la mort',
    'se reconnaît tout de suite',
    'je suppose',
    'je recherche le tempêtement permanent',
    'des voitures dans la rue',
    'ça me garde ici',
    'ce lit ce mur',
    'cette Rue de la Tulipe ce Bruxelles',
    'je déteste ce silence',
    'qui laisse percevoir le trottinement du temps',
    'une respiration sifflante',
    'des gouttes qui tombent dans un évier',
    'les espaces où on attend',
    'chaque son est un bien',
    'il fait chaud dans ma chambre sans fenêtre',
    'la nuit traîne dedans des rêves usés',
    'le cliquetis du ventilateur',
    '’s infléchit à chaque fois',
    'vers le bruit d’une gâchette',
    'un fusil sans balles',
    'chaque nuit je meurs en silence',
    'mais pas ici pas',
    'dans cette ville',
    'où de toute façon',
    'le silence tombe',
    'plus durement',
    'qu’une bombe',
    ],
    [
   'there are different kinds of silence',
   'the rippling',
   'the silence of chaos',
   'the silence before/after',
   'the perfect',
   'silence of death',
   'is a kind you can tell at once',
   'I suppose',
   'I seek the continuous raving',
   'of cars in the street',
   'it keeps me here',
   'this bed this wall',
   'this Tulpstraat this Brussels',
   'I hate the kind of silence',
   'through which one can hear time toddling',
   'a wheezy breathing',
   'drops dripping in a kitchen sink',
   'spaces people wait in',
   'every sound is a good',
   "it's hot in my windowless room",
   'the night drags in some worn-out dreams',
   'the tapping of a fan',
   'keeps on turning',
   'into the noise of a trigger',
   'a bulletless gun',
   'each night I die without a sound',
   'but not here not',
   'in this city',
   'where silence falls',
   'at any rate',
   'with a blast more roaring',
   'than a bomb',
  ],
],
},
{
  id: 8,
  marker : {
    lat: 50.835476,
    lng: 4.365485
  },
  place: [
    'Future Place Lumumba',
    'Futur Place Lumumba',
    'Futur Place Lumumba',
  ],
  title: [
    'Future Place Lumumba',
    'Futur Place Lumumba',
    'Futur Place Lumumba',
  ],
  subtitle: [
    'L’Athenée',
    "Rue de l'Athénée",
    "Rue de l'Athénée",
  ],
  body: [
    [
    'aan de kerk op het plein breit een vrouw',
    'onder duur betaald licht',
    'een meterslange sjaal',
    'tatoeëert met breinaalden',
    'deukjes in haar palmen',
    'terwijl ze spreekt:',
    '         ik heb spijt van alles wat ik zeg',
    '',
    'achter een raam ligt een zwarte kat',
    'in een rieten mandje opgebaard',
    'plastic bloemen, een briefje tegen het glas',
    'MARY      PAS BIEN     DODO',
    '',
    'drie magere jongens',
    'roepen tegen elkaar',
    'zonder schakering in hun stem',
    'lopen gebocheld door handboeken',
    'de breiende vrouw voorbij',
    'lossen op een massa blauw',
    'in het café op de hoek',
     '',
    'vraagt een man in pak',
    'hoe laat is het',
    'hij lijkt verloren',
    'in de zee van stemmen de stickers op de tafels',
    'waar roken drinken roken drinken',
    'klinkt en ook:',
     '',
    'het is vier uur',
    'man',
    'leg je werk neer',
     '',
    'de man stapt uit de bar zijn haren nat',
    'kijkt op het plein omhoog een papieren straatnaambord',
    'de rug van een kerk',
    'volgt de lange sjaal de tikkende naalden',
    'leest het briefje achter het raam',
    'wie heeft wat gedaan',
    '',
    'hij legt zijn handen naast zich neer',
    'draait zich om',
    'stijgt op in',
    'blank licht',
     '',
    'even opent Mary haar ogen',
    'geeuwt',
    'en dicht',
    '',
    '',
    '',
    '',
    '',
    ''
    ],
    [
     "à l'église sur la place sous une lumière chèrement payée",
     'une femme tricote',
     'une écharpe de plusieurs mètres de long',
     'avec des aiguilles elle se tatoue',
     'des petites encoches dans les paumes',
     "pendant qu'elle parle:",
     'je regrette tout ce que je dis',
     'derrière une fenêtre, un chat noir est allongé',
     'en bière dans un panier de jonc',
     'des fleurs en plastic, un bout de papier contre le vitrage',
     'MARY PAS BIEN DODO',
     'trois garçons maigres',
     "s'entrecrient",
     'leurs voix dépourvues de nuances',
     'bossus par des manuels ',
     'ils passent la femme tricotante ',
     'se dissolvent dans une masse de bleu',
     'dans un café au coin',
     'un homme en costume demande',
     "l'heure",
     'il semble perdu',
     'dans un flot de voix les autocollants sur les tables',
     'où ça résonne',
     'fumer boire fumer boire et aussi:',
     'il est quatre heure',
     'homme',
     'cesse ton travail',
     "l'homme sort du café les cheveux mouillés",
     'sur la place il regarde en haut voit une plaque en papier qui indique le',
     'nom',
     "le dos de l'église",
     "il suit l'écharpe longue les aiguilles qui font tic-tac",
     'lit le papier derrière la fenêtre',
     'qui a fait quoi',
     'il baisse les bras',
     'fait demi-tour',
     "s'envole dans une",
     'lumière blanche',
     'un instant Mary entrouvre les yeux',
     'bâille',
     'et referme',
     '',
    '',
    '',
    '',
    '',
    ''
    ],
    [
     'near the church on the square under dearly paid light',
     'a woman is knitting',
     'a scarf several yards long',
     'with knitting needles she is tattooing',
     'little notches in her handpalms',
     'while she is speaking:',
     'I regret everything I say',
     'behind a window a black cat lies',
     'in state in a wickerwork basket',
     'plastic flowers, a note against the glass',
     'MARY PAS BIEN DODO',
     'three skinny boys ',
     'shouting at each other',
     'their voices devoid of nuance',
     'walking hunchbacked by their school books',
     'past the knitting woman',
     'and dissolve into a mass of blue',
     'in a bar on the corner',
     'a man in suit asks',
     'what time is it',
     'he seems lost',
     'in the flood of voices the stickers on the tables',
     'the sound of smoking drinking smoking drinking and also:',
     "it's four o'clock",
     'man ',
     'lay down your work',
     'the man steps out of the bar his hair wet',
     'on the square he looks op a paper street sign',
     'the back of the church',
     'follows the long scarf the ticking needles',
     'reads the note behind the window',
     'who has done what',
     'he lowers his hands ',
     'turns around',
     'lifts off in ',
     'white light',
     'one moment Mary opens her eyes',
     'yawns',
     'and shuts',
    ],
  ],
  },
  {
    id: 9,
    marker : {
      lat: 50.833830,
      lng: 4.366806
    },
    place: [
      'Tulpstraat',
      'Rue de la Tulipe',
      'Rue de la Tulipe',
    ],
    title: [
      'Tulpstraat',
      'Rue de la Tulipe',
      'Rue de la Tulipe',
    ],
    subtitle: [
      'voor Karima',
      'Pour Karima',
      'For Karima',
    ],
    body: [
      [
      'hoorde je het geluid van tijgers al',
      'hun zingen als klagen als vrouwen',
      'door heimwee gekromde graten nee',
      'mijn lasten zijn niet licht',
       '',
      'het huis van mijn lichaam heeft dunne muren',
      'wanneer ik met mijn vingers',
      'over de gedekte tafel strijk ',
      '',
      'luister naar de beschilderde klanken',
      'mijn lied voor de profeet',
       '',
      'hoorde je het geluid van tijgers al',
      'het Marokkaanse platteland de vogels de wind',
      'die door de deuren trekt handen doet klappen',
      'oker, het diagonale avondlicht',
       '',
      'in deze straat rammelen borden en bestek',
      'elke nacht de geesten van onder mijn bed',
      'terwijl ik bid',
       '',
      'als het stil zou zijn',
      'hoor je hoe de tijgers',
      'hoe mijn zuiders hart',
      'langzaam belegen',
      'kamers leegt',
      'vertrekt'
      ],
      [
       'as-tu déjà entendu le cri des tigres',
       'leur chant comme une plainte comme des femmes',
       'des épines courbées par le mal du pays non',
       'mes fardeaux ne sont pas légers',
       'la maison de mon corps a des murs fins',
       "quand j'effleure",
       'la table dressée de mes doigts',
       'écoute les sons peints en couleurs',
       'ma chanson pour le prophète',
       'as-tu déjà entendu le cri des tigres',
       'la campagne marocaine les oiseaux le vent',
       'qui court par les portes fait battre des mains',
       "de l'ocre, la lumière diagonale du soir",
       'dans cette rue le cliquetis de plats et de couverts',
       'chasse les phantômes de sous mon lit',
       'pendant que je prie',
       "s'il était silencieux",
       'tu entendrais comment les tigres',
       'comment mon coeur de sudiste',
       'vide lentement',
      ],
      [
       'have you ever heard the sound of tigers',
       'their songs like moaning like women',
       'spines bent by homesickness no',
       'my burdens are not light',
       'the house of my body has thin walls',
       'when I stroke ',
       'the set table with my fingers',
       'listen to the painted sounds',
       'my song for the prophet',
       'have you ever heard the sound of tigers',
       'the Moroccan countryside the birds the wind',
       'that is blowing through the doors making hands clap',
       'ochre, the slant evening light',
       'in this street plates and cutlery rattle',
       'the ghosts from under my bed every night',
       'while I am praying',
       'if it was quiet',
       'you would hear how the tigers',
       "how my southerner's heart",
       'slowly empties out',
       'ripe rooms',
       'departs',
      ],
    ],
    img: '/tulpstraatfoto.jpg'
    },
    {
      id: 10,
      marker : {
        lat: 50.832777,
        lng: 4.363911
      },
      place: [
        'PSC Sint-Alexius ',
        'PSC Sint Alexius',
        'PSC Sint Alexius',
      ],
      title: [
        'PSC Sint-Alexius ',
        'PSC Sint Alexius',
        'PSC Sint Alexius',
      ],
      subtitle: [
        'voor Myriam',
        'Pour Myriam',
        'For Myriam',
      ],
      body: [
        [
        'ik leef in twee ritmes',
        'maar hoorde nooit de stem van God',
         '',
        'mijn hart bekent alleen',
        'als het bang is of verliefd',
        '',
        'slaapt die al?',
        'waar zit zij?',
        'zijt gij der nog?',
        '',
        'wanneer de avond schuifelt',
        'komen de mussen langs',
        'ze babbelen',
        'ik luister mee en kijk',
         '',
        'een breed blauw verdriet daalt',
        'als nevel over de tuin',
        '',
        'het klotsen van het water',
        'twee ritmes tegen mijn slapen',
        '',
        'de wereld onder een deken',
        'hoog opgetrokken over het hoofd',
        'de voeten koud en bloot',
         '',
        'wist je dat een stadsmus sneller sterft',
        'omdat ze te veel junkfood eet',
        '',
        'als een kanarie',
        'in een mijn',
        '',
        'zwijg en herteken ik',
        'mijn gedachten',
        'dag en nacht',
        'tot een dunne lijn',
        ],
        [
         'je vis à deux rythmes',
         "mais je n'ai jamais entendu la voix de Dieu",
         'mon coeur ne confesse que',
         'quand il a peur ou est amoureux',
         'celui-là, il dort déjà?',
         'où se trouve-t-elle?',
         "t'es toujours là?",
         'quand la soirée traîne les pieds',
         'les moineaux passent nous voir',
         'ils bavardent',
         "je suis à l'écoute et regarde",
         'une tristesse large et bleue descend',
         'comme la brume sur le jardin',
         "le clapotage de l'eau",
         'deux rythmes contre mes tempes insomniaques',
         'le monde sous une couverture',
         'haut remontée sur la tête',
         'les pieds froids et nus',
         "savais-tu qu'une moineau de ville meurt plus vite",
         "parce qu'elle mange trop de malbouffe",
         'comme un canari',
         'dans une mine',
         'je me tais et redessine',
         'mes pensées',
         'jour et nuit',
         "jusqu'à en faire une ligne fine",
        ],
        [
         'I live to two rhythms',
         "but I've never heard the voice of God",
         'my heart only confesses',
         'when in fear or in love',
         'would that fellow sleep already?',
         'where is she?',
         'are you still there?',
         'when the evening is shuffling',
         'the sparrows come along',
         'they are chattering',
         'I listen in and look',
         'a broad and blue sadness sinks',
         'like mist over the garden',
         'the lapping of the water',
         'two rhythms against my sleepless temples ',
         'the world under a blanket',
         'pulled up high over the head',
         'the feet cold and bare',
         'did you know that a city sparrow dies earlier',
         'because she eats too much junk food',
         'like a canary',
         'in a mine',
         'I hush and redraw',
         'my thoughts',
         'day and night',
         'to a thin line',
        ],
      ],
      img: '/tekeningmyriam.jpg'
      },
      {
        id: 11,
        marker : {
          lat: 50.831229,
          lng: 4.363964
        },
        place: [
          'PSC Sint-Alexius ',
          'PSC Sint-Alexius ',
          'PSC Sint-Alexius ',
        ],
        title: [
          'PSC Sint-Alexius ',
          'PSC Sint-Alexius ',
          'PSC Sint-Alexius ',
        ],
        subtitle: [
          '', '', '',
        ],
        body: [
          [
          'sommige dingen zijn alleen bedoeld',
          'om van ver te zien',
          'boten, schoorstenen, een zwaaiend kind',
          'blote borsten op een brug',
          '',
          'maar zie ons hier zitten',
          'als wakkere veulens',
          'oplaaiende harten',
          'sommigen eeltig als',
          'het wit op een pompelmoes',
          '',
          'haar naam is De Schepper',
          'als god van hemel en aarde',
          'haar stem wappert als wasgoed aan een lijn',
           '',
          'mijn naam past me nog niet helemaal',
          'maar geen paniek',
          'ik groei er in',
          '',
          'wij verlangen naar de natuur',
          'de natuurlijke staat van dingen',
          'we verlangen naar het slaan van stof uit tapijt',
          '',
          'wij zingen als kanaries in donkere tunnels',
          'onze huid is heet heet heet',
          'wij bezitten de tijd',
           '',
          'maar de muren zijn dun',
          'de ramen gesloten',
          'midden september en',
          'de lucht koelt weer af',
           '',
          'wie ziet ons niet?',
          'wie hoort ons niet?',
          'wie loopt voorbij',
          'de stuwing van ons lied?',
          '',
          'in de verte',
          '',
          'een groot paard nadert',
          'stapt de poort binnen',
          'staat tussen ons in',
          'blaast de lucht rond zich',
          'met wolken warm',
          '',
          '',
          '',
          '',
          '',
          ''
          ],
          [
            'certaines choses ne sont destinées',
            "qu'à être vues de loin",
           'les bateaux, les cheminées, un enfant faisant signe de la main',
           'des seins nus sur un pont',
           'mais regarde-nous assis ici',
           'comme des poulains éveillés',
           'les cœurs flamboyants',
           'certains calleux comme',
           "l'albédo sur une pamplemousse",
           "elle s'appelle De Schepper ",
           'comme le dieu du ciel et de la terre',
           'elle a voix flottante comme du linge pendu ',
           'mon nom ne me va pas encore tout à fait',
           'mais pas de panique',
           'je grandis',
           'nous languissons après la nature',
           'l’état naturel des choses',
           'nous languissons après le battage de poussière de tapis',
           'nous chantons comme des canaris dans des galeries ténébreuses',
           'notre peau est chaude chaude chaude',
           'nous possédons le temps',
           'mais les murs sont minces',
           'les fenêtres fermées',
           'mi-septembre et',
           'l’air se rafraîchit',
           'qui ne nous voit pas?',
           'qui ne nous entend pas?',
           'qui passe devant',
           'l’engorgement de notre chant',
           'au loin',
           'un grand cheval s’approche',
           'entre par la porte',
           's’arrête entre nous',
           "en exhalant des nuages dans l’air l'entourant",
           'il le réchauffe',          
          ],
          [
           'some things are only meant',
           'to be seen from afar',
           'boats, chimneys, a waving child',
           'bare breasts on a bridge',
           'but look at us sitting here',
           'like brisk foals',
           'hearts blazing up',
           'some callous like',
           'the pith on a pomelo',
           'her name is De Schepper',
           'like the god of heaven and earth',
           'her voice flutters as laundry on a line',
           'my name does not quite fit me yet ',
           'but no panic',
           'I grow into my part',
           'we long for nature',
           'the natural state of things',
           'we long for the beating of dust out of rugs',
           'we sing like canaries in dark tunnels',
           'our skin is hot hot hot',
           'we own the time',
           'but the walls are thin',
           'the windows closed',
           'mid-September and ',
           'the air is cooling down again',
           'who doesn’t see us?',
           'who doesn’t hear us?',
           'who is walking past',
           'the swelling of our song?',
           'in the distance',
           'a big horse is drawing nearer',
           'he enters the gate',
           'stands in between us',
           'and with clouds he blows the air around him',
           'warm',
          ],
        ],
        },
  ],
  showList: true,
  zoom : 15
}

const state = (state = initialState, action) => {
  switch (action.type) {
    case DISABLE_DETAIL_SELECTED:
      return {
        ...state,
        detailSelected: 0,
        isDetailSelected: false
      }
    case HIDE_LIST:
      return {
        ...state,
        showList: false
      }
    case SET_AUDIO_SELECTED:
      return {
        ...state,
        audioSelected: action.id
      }
    case SET_DETAIL_SELECTED:
      return {
        ...state,
        //center: state.locations[action.id].marker,
        detailSelected: action.id,
        isDetailSelected: true
      }
    case SET_LANGUAGE: 
      return {
        ...state,
        language: action.lang
      }
    case SET_NAVIGATION:
      return {
        ...state,
        navigation: action.id
      }
    case SET_MOBILE_TRUE:
      return {
        ...state,
        isMobile: true,
        showList: false
      }
    case SHOW_LIST:
      return {
        ...state,
        showList: true
      }
    default:
      return state
  }
}

export default state
