export const DISABLE_DETAIL_SELECTED = 'DISABLE_DETAIL_SELECTED';
export const HIDE_LIST = 'HIDE_LIST';
export const SET_AUDIO_SELECTED = 'SET_AUDIO_SELECTED';
export const SET_DETAIL_SELECTED = 'SET_DETAIL_SELECTED';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_NAVIGATION = 'SET_NAVIGATION';
export const SET_MOBILE_TRUE = 'SET_MOBILE_TRUE';
export const SHOW_LIST = 'SHOW_LIST';

export function disableDetailSelected() {
  return { type: DISABLE_DETAIL_SELECTED  }
}

export function hideList() {
  return { type: HIDE_LIST }
}

export function setAudioSelected(id) {
  return {  type: SET_AUDIO_SELECTED,
            id
          }
}

export function setDetailSelected(id) {
  return {  type: SET_DETAIL_SELECTED,
            id
          }
}

export function setLanguage(lang) {
  return {
    type: SET_LANGUAGE,
    lang
  }
}
export function setNavigation(id) {
  return {
            type: SET_NAVIGATION,
            id
  }
}

export function setMobileTrue() {
  return {
            type: SET_MOBILE_TRUE
  }
}

export function showList() {
  return {
    type: SHOW_LIST
  }
}
