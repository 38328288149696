import React, { Component } from 'react';
import Leaflet from 'leaflet';
import {Button, ButtonGroup, ButtonToolbar, Glyphicon} from 'react-bootstrap';
import {connect} from 'react-redux';
import Iframe from 'react-iframe';
import Protect from 'react-app-protect';


import { setAudioSelected, setLanguage, setNavigation, showList } from '../actions';

//import ControlContainer from '../containers/ControlContainer';
import AudioPlayer from '../components/AudioPlayer';
import DetailContainer from '../containers/DetailContainer';
import MapContainer from '../containers/MapContainer';
import CustomNav from '../components/CustomNav';
import Wat from '../components/Wat';
import '../../css/leaflet.css';
import '../../css/App.css';


Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/images/'

class App extends Component {
  constructor(props) {
      super(props);
      this._handleNavClick = this._handleNavClick.bind(this);
      this._handleLanguageClick = this._handleLanguageClick.bind(this);
      this._handleListButtonClick = this._handleListButtonClick.bind(this);
      this._handleCloseAudioplayer = this._handleCloseAudioplayer.bind(this);
  }

  _handleNavClick(id) {
      console.log('new nav click', id)
      if(this.props.navigation !== id) {
        this.props.dispatch(setNavigation(id));
      }
  }

  _handleLanguageClick(lang) {
    console.log('new language selected', lang);
    if(this.props.language !== lang) {
      this.props.dispatch(setLanguage(lang));
    }
  }

  _handleCloseAudioplayer() {
    console.log('close audioplayer');
    this.props.dispatch(setAudioSelected(0));
  }

  _handleListButtonClick() {
    this.props.dispatch(showList());
  }

  render() {
    let content, audioPlayer, mobileControl = null;
    let nav = this.props.navigation;
    let { language } = this.props;
    if(this.props.audioSelected > 0) {
      audioPlayer =
      <span>
        <AudioPlayer key={`audioplayer_${this.props.audioLocation.id}`}audioSelected={this.props.audioSelected} audioLocation={this.props.audioLocation} onCloseClick={this._handleCloseAudioplayer}/>
      </span>;
      //audioId = this.props.audioLocation.id;
    }
    if(this.props.isMobile) {
      mobileControl =
      <ButtonToolbar className='mobile-control'>
        <ButtonGroup className='group'>
          <Button className={ language==='NL' ? 'active' : ''} eventKey={1} onClick={() => this._handleLanguageClick('NL')}>nl</Button>
          <Button className={ language==='FR' ? 'active' : ''} eventKey={1} onClick={() => this._handleLanguageClick('FR')}>fr</Button>
          <Button className={ language==='EN' ? 'active' : ''} eventKey={1} onClick={() => this._handleLanguageClick('EN')}>en</Button>
        </ButtonGroup>
        <Button className="btn-list" onClick={() => this._handleListButtonClick()}>
          <Glyphicon glyph='th-list'/>
        </Button>
      </ButtonToolbar>
    }
    if(nav === 1) {
      content =
      <span>
      <div className='content'>
        <MapContainer/>
        <DetailContainer/>
      </div>
      {audioPlayer}
      {mobileControl}
      </span>;
    } else if (nav === 2) {
      content =
      <div className='content'>
        <Wat/>
      </div>;
    } else if (nav === 3) {
      content = <Iframe url="https://stadsklanken.tumblr.com/"
              position="absolute"
              width="100%"
              height="100%"
              styles={{height: "100%", marginTop: "50px"}}
              allowFullScreen
      />
    }

    return (
        <Protect 
            sha512='07f314bb70f436807af6d74e47107cc66c86d4af249b9f3862e16906a2e5217647adcbec9d59669719c3e35f7021153c88fd2b6caff91b8456cd23de9b3595c8'
            className="password"
            blur={
                true
            }
            styles={{
                input: { color: "blue"},
                header: {fontSize: "20px"}
                }}
            >
            <div className="App">
                <CustomNav navActive={this.props.navigation} language={this.props.language} onNavClick={this._handleNavClick} onLanguageClick={this._handleLanguageClick}/>
                {content}
            </div>
        </Protect>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    audioSelected: state.audioSelected,
    audioLocation: state.audioLocations[state.audioSelected - 1],
    isMobile: state.isMobile,
    language: state.language,
    navigation: state.navigation,
  }
}

export default connect(mapStateToProps)(App);
