import React, { Component } from 'react';
import { connect } from 'react-redux';
import { disableDetailSelected, hideList, setDetailSelected } from '../actions';
import DetailImg from '../components/DetailImg';
import DetailVideo from '../components/DetailVideo';
import { Col, Glyphicon, Grid, Row } from 'react-bootstrap';

/*
TODO:
  - remove control store
  - add onMapClick
*/
class DetailContainer extends Component {
  constructor() {
    super();
    this._dimensions = 0;
    this._handleBackClick = this._handleBackClick.bind(this);
    this._handleRemoveClick = this._handleRemoveClick.bind(this);
    this._handleListClick = this._handleListClick.bind(this);
    this._handleRemoveListClick = this._handleRemoveListClick.bind(this);
    this._tempDetailDimensions = 0;
  }

  _handleBackClick(){
    console.log('back');
    this.props.dispatch(disableDetailSelected());
  }
  _handleRemoveClick(){
    this.props.dispatch(disableDetailSelected());
  }

  _handleListClick(key){
    this.props.dispatch(setDetailSelected(key+1));
  }

  _handleRemoveListClick() {
    this.props.dispatch(hideList());
  }

  render() {
    ///SOrry no time to figure it out
    let bodyIndex = 0;
    if(this.props.language === 'FR') {
      bodyIndex = 1;
    } else if (this.props.language === 'EN') {
      bodyIndex = 2;
    }
    if(this.props.isDetailSelected) {
      return (
        <Grid className={ 'detail-layer show'}>
          <Row className='detail-header body'>
            <div className='header'>
              <h3>{this.props.detailSelected}. {this.props.location.title[bodyIndex]}</h3>
              <h4>{this.props.location.subtitle[bodyIndex]}</h4>
            </div>
            <div className='close-button'>
              <Glyphicon className='icon-big' glyph="remove" onClick={() => this._handleRemoveClick()}/>
            </div>
          </Row>
          <Row className='body padding-bottom-100'>
           <Col className="col-xs-12">
            {this.props.location.body[bodyIndex].map( (string, index) => (
              <h5 key={`string_${index}`}>{string}</h5>
            ))}
            </Col>
            <DetailImg img={this.props.location.img}/>
            <DetailVideo video={this.props.location.video}/>
          </Row>
          <Row className="detail-back" onClick={() => this._handleBackClick()}>
              <Glyphicon className='icon-small' glyph="arrow-left"/>
              <h6>Terug naar kaart</h6>
          </Row>
        </Grid>
      )
    } else if(this.props.showList && this.props.isMobile){
      return (
      <Grid className={ 'detail-layer list show'}>
        <Row className='list-button'>
          <Glyphicon className='icon-big remove-list' glyph='remove' onClick={()=> this._handleRemoveListClick()}/>
        </Row>
        <ul className="list-group">
          {this.props.locations.map((location, index) =>(
            <li key={`li+${index}`} className="list-group-item" onClick={()=>this._handleListClick(index)}>
                <h3>{index + 1}. {location.title[bodyIndex]}</h3>
                <h4>{location.subtitle[bodyIndex]}</h4>
            </li>
        ))}
        </ul>
      </Grid>
      )
    } else if(this.props.showList && !this.props.isMobile){
      return (
        <Grid className={ 'detail-layer list show'}>
        <Row className='list-button'>
          <Glyphicon className='icon-big remove-list' glyph='remove' onClick={()=> this._handleRemoveListClick()}/>
        </Row>
        <ul className="list-group">
          {this.props.locations.map((location, index) =>(
            <li key={`li+${index}`} className="list-group-item" onClick={()=>this._handleListClick(index)}>
                <h3>{index + 1}. {location.title[bodyIndex]}</h3>
                <h4>{location.subtitle[bodyIndex]}</h4>
            </li>
        ))}
        </ul>
      </Grid>
      )
    }else {
      return (
        null
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    detailSelected: state.detailSelected,
    isDetailSelected: state.isDetailSelected,
    isMobile: state.isMobile,
    language: state.language,
    location: state.locations[state.detailSelected - 1],
    locations: state.locations,
    showList: state.showList
  }
}

export default connect(mapStateToProps)(DetailContainer);
